<template>
    <div>
        <div class="mt-5 bg-white shadow-xl rounded-lg flex py-3 px-5 items-center">
            <el-collapse class="flex-1">
                <el-collapse-item>
                    <template slot="title">
                        <div class="flex w-full px-4 items-center cursor-pointer" @click="toggle = !toggle">
                            <div class="flex-1 flex items-center">
                                <h2 class="text-base">狀態：{{ order.status | accountStatus }}</h2>
                                <i
                                    :class="toggle ? 'transform rotate-90 ' : 'transform rotate-0 '"
                                    class="ml-2 duration-200 transition ease-linear ni ni-bold-right"></i>
                            </div>
                            <button class="orange-btn-800 md:text-sm text-xs leading-none" @click.stop="toFail($route.params.id)">標示異常</button>
                        </div>
                    </template>
                    <div v-for="log in order.log.slice().reverse()" :key="log.time" class="border-b border-gray-100 mb-2 pb-2">
                        <p class="text-gray-300 text-sm mt-2">
                            撥款完成：
                            <span class="text-black">{{ order.complete_time | formatDateTime }}</span>
                            <span class="text-black ml-2">| {{ log.user_id }}</span>
                        </p>
                        <p class="text-gray-300 text-sm mt-2">
                            撥款處理：
                            <span class="text-black">{{ order.transaction_date | formatDateTime }}</span>
                            <span class="text-black ml-2">| {{ log.user_id }}</span>
                        </p>
                        <p class="text-gray-300 text-sm mt-2">
                            提出申請：
                            <span class="text-black">{{ order.created_at | formatDateTime }}</span>
                            <span class="text-black ml-2">| {{ order.broker.name }}</span>
                        </p>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="p-5 bg-white rounded-lg shadow-xl mt-5">
            <div class="max-w-[1000px] 2xl:mx-auto lg:mx-20">
                <h3 class="font-bold text-xl">代付款項資訊</h3>
                <div class="flex mt-2">
                    <div class="flex-1">
                        <p class="text-gray-300 text-sm mt-2">
                            提領人：<span class="text-black">{{ order.broker.name }}</span>
                        </p>
                        <p class="text-gray-300 text-sm mt-2">
                            提領金額：<span class="text-black">${{ order.amount | formatCurrency }}</span>
                        </p>
                    </div>
                    <div class="flex-1 border-l border-gray-100 pl-5">
                        <p class="text-gray-300 text-sm mt-2">
                            申請時間：<span class="text-black">{{ order.created_at | formatDateTime }}</span>
                        </p>
                        <p class="text-gray-300 text-sm mt-2">
                            匯出TXT：<span class="text-black">{{ order.export_time | formatDateTime }}</span>
                        </p>
                        <p class="text-gray-300 text-sm mt-2">
                            預計撥款：<span class="text-black">{{ order.transaction_date | formatDateTime }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="border-t border-gray-100 mx-10 my-5"></div>
            <div class="max-w-[1000px] 2xl:mx-auto lg:mx-20">
                <h3 class="font-bold text-xl">帳戶資訊</h3>
                <div class="flex mt-2">
                    <div class="flex-1">
                        <p class="text-gray-300 text-sm mt-2">
                            銀行代碼：<span class="text-black">{{ order.banking.bankCode }}</span>
                        </p>
                        <p class="text-gray-300 text-sm mt-2">
                            銀行戶名：<span class="text-black">{{ order.banking.accountName }}</span>
                        </p>
                    </div>
                    <div class="flex-1 border-l border-gray-100 pl-5">
                        <p class="text-gray-300 text-sm mt-2">
                            通匯代碼：<span class="text-black">{{ order.banking.swift }}</span>
                        </p>
                        <p class="text-gray-300 text-sm mt-2">
                            銀行帳號：<span class="text-black">{{ order.banking.accountId }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mt-10 mb-5">
                <button
                    class="border border-black rounded-lg text-black transition duration-200 hover:bg-black hover:text-white px-5 py-2"
                    @click.prevent="$router.push({ name: 'salary_withdraw_broker' })">
                    返回記錄列表
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import "../filterAccount";
export default {
    data() {
        return {
            order: {},
            // toggle 狀態
            toggle: false,
        };
    },
    methods: {
        /**
         * 標示異常
         * @param { type String or Number(字串或數字) } id 訂單 id
         */
        async toFail(id) {
            try {
                await this.$api.UpdateBrokerRemittanceApi({ id, status: -1 });
                this.$message({
                    type: "success",
                    message: "標示異常成功",
                });
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "標示異常失敗",
                });
            }
        },
        /**
         * 取得資料
         */
        async getData(id) {
            try {
                const { data } = await this.$api.GetBrokerRemittanceApi(id);
                this.order = data;
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得介紹人代付款項資料失敗",
                });
            }
        },
    },
    async created() {
        await this.getData(this.$route.params.id);
    },
};
</script>

<style scoped lang="scss">
::v-deep {
    .el-collapse {
        border: none;
    }
    .el-collapse-item__header {
        border: none;
    }
    .el-collapse-item__arrow {
        display: none;
    }
    .el-collapse-item__wrap {
        border: none;
    }
}
</style>
